<h3>Change Password</h3><br>
<div class="card ">
  <form class="m-1" [formGroup]="docForm">
    <div class="row" style="padding-top: 9%;" >
      <div class="col-xl-5" style="margin-top: 3%;">
        &nbsp;<mat-label>Old Password :</mat-label>
      </div>
      <div class="col-xl-7">
        <mat-form-field class="example-full-width form-control-sm" appearance="outline">
          <mat-label>Old Password</mat-label>
            <input  class="inputTxt" (blur)="validateOldPassword($event.target.value)" matInput [type]="hideOldPassword ? 'password' : 'text'" formControlName="oldChangepassword" required>
              <a  class="show-pwd-icon" href="#" onClick="return false;" matSuffix (click)="hideOldPassword = !hideOldPassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideOldPassword" value autocomplete="off">
                <mat-icon  >{{hideOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>   
          <mat-error *ngIf="docForm.controls['oldChangepassword'].hasError('oldPwd') ">
            Old password doesn't match in our records.Please be correct
          </mat-error>   
        </mat-form-field>
      </div>
    </div>
    <div class="row" style="margin-top: 3%;">
      <div class="col-xl-5" style="margin-top: 3%;">
          &nbsp;<mat-label>New Password : </mat-label>
      </div>
      <div class="col-xl-7">
        <mat-form-field class="example-full-width form-control-sm" appearance="outline">
          <mat-label>New Password</mat-label>
            <input  class="inputTxt" (blur)="validateOldPasswordAndNewPassword($event.target.value)" matInput [type]="hideNewPassword ? 'password' : 'text'" formControlName="newChangePassword" required>
              <a class="show-pwd-icon" href="#" onClick="return false;" matSuffix (click)="hideNewPassword = !hideNewPassword"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNewPassword">
                  <mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a> 
          <mat-error *ngIf="docForm.controls['newChangePassword'].hasError('oldValid') ">
            You can't change New password same as your Old password
          </mat-error> 
          <mat-error *ngIf="docForm.controls['newChangePassword'].hasError('required') && docForm.controls['newChangePassword'].touched">
            New Password Required
          </mat-error>   
          <mat-error *ngIf="(docForm.controls['newChangePassword'].hasError('passwordStrength'))">
            Password must have One Upper,LowerCase,Alphabetic And Special Character(except # symbol)
          </mat-error> 
          <mat-error  *ngIf="docForm.controls['newChangePassword'].hasError('minlength')">
            Password must have 6 characters
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" style="margin-top: 3%;">
      <div class="col-xl-5" style="margin-top: 3%;">
        &nbsp;<mat-label>Confirm New Password  </mat-label>
      </div>
      <div class="col-xl-7">
        <mat-form-field class="example-full-width form-control-sm" appearance="outline">
          <mat-label>Confirm New Password</mat-label>
            <input  class="inputTxt" matInput [type]="hideconfirmNewPassword ? 'password' : 'text'" formControlName="confirmChangePassword" required>
              <a class="show-pwd-icon" href="#" onClick="return false;" matSuffix (click)="hideconfirmNewPassword = !hideconfirmNewPassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideconfirmNewPassword">
                <mat-icon>{{hideconfirmNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a> 
          <mat-error *ngIf="docForm.controls['confirmChangePassword'].hasError('required') && docForm.controls['confirmChangePassword'].touched">
            Confirm New Password Required
          </mat-error>
          <mat-error *ngIf="docForm.controls['confirmChangePassword'].hasError('mustMatch')">
            Passwords Must Match. Please Refer New Password
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <br>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3" style="margin-top: 3%;">
      <button (click)="updatePassword()" class="btn-space" mat-raised-button
        style="margin-left: 34%; color: aliceblue; background-color: green;">Update</button>
      <button (click)="cancel()" class="btn-space" color="warn" type="button" mat-raised-button >Cancel</button>
    </div>  
  </form>
</div>
