<nav #navbar class="navbar active">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}}>
         <!-- <img src="assets/images/logo.png" alt="" />  -->
        <img *ngIf="logoList==null" src="assets/images/logo.png" alt="" />  
        <img *ngIf="logoList!=null" src={{path}} alt="" [class]="'company_logo'" />
        <!-- <span class="logo-name">BituTech</span> -->
      </a>
    </div>
    <app-matdynamicmenu></app-matdynamicmenu>
    <div _ngcontent-cud-c186 style="margin-left: auto;">

      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <app-feather-icons [icon]="'maximize'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>

        <li *ngFor="let invoice of invoiceList" class="nav-item btnNotification">
          <button *ngIf="show" mat-icon-button (click)="mailCall()" class=" nav-notification-icons">
            <span>     <i class="fas fa-envelope fa-6x col-white" style="margin-top: 14px; margin-left: -10px;"></i>  </span>   
            <!-- <span class="material-icons">notifications</span> -->
            <span class="icon-button__badge">{{invoice.invoiceCount}}</span>
            <!-- <span class="badge" style="text-transform: capitalize ; color: white;">({{invoice.invoiceCount}})</span> -->
          </button>
          <!-- <i class="fa-sharp fa-solid fa-bell"></i> -->
          <button *ngIf="custshow" mat-icon-button (click)="salesCall()" class=" nav-notification-icons">
            <span>     <i class="fas fa-bell fa-6x col-white" style="margin-top: 14px; margin-left: -10px;"></i>  </span>   
            <!-- <span class="material-icons">notifications</span> -->
            <span class="icon-button__badge">{{salesCallCount}}</span>
            <!-- <span class="badge" style="text-transform: capitalize ; color: white;">({{invoice.invoiceCount}})</span> -->
          </button>
        </li>

        <!-- <li class="nav-item btnNotification" ngbDropdown>
        
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 350px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="nfc-header">
                  <h5 class="mb-0">Notitications</h5>
                </li>
                <li>
                  <a href="#" *ngFor="let notification of notifications" onClick="return false;"
                    [ngClass]="[notification.status]">
                    <span class="table-img msg-user ">
                      <i class="material-icons-two-tone nfc-type-icon"
                        [ngClass]="[notification.color]">{{notification.icon}}</i>
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.message}}</span>
                      <span class="menu-desc mt-2">
                        <i class="material-icons">access_time</i> {{notification.time}}
                      </span>
                    </span>
                    <span class="nfc-close">
                      <app-feather-icons [icon]="'x'" [class]="'user-menu-icons'"></app-feather-icons>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a class="nfc-read-more">Read
              All Notifications</a>
          </div>
        </li> -->
        <li class="nav-item user_profile btnUserDropdown" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <!-- <img src="{{userImg}}" class="user_img" width="32" height="32" alt="User"> -->
            <!-- <img src="assets/images/logo.png" class="user_img" width="32" height="32" alt="User"> -->
            <!-- <img src={{filePath}}{{companyImg}} class="user_img" width="32" height="32" alt="User"> -->
            <!-- <img src={{path}} class="user_img" width="32" height="32" alt="User"> -->
            <img *ngIf="logoList==null" src="assets/images/logo.png" class="user_img" width="32" height="32" alt="" />  
            <img *ngIf="logoList!=null" src={{path}} alt="" [class]="'company_logo'" />
            <span style="text-transform: uppercase ;">{{userName}}</span>
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown" >
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Account
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'mail'" [class]="'user-menu-icons'"></app-feather-icons>Inbox
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings
                      </a>
                    </li>
                    <li>
                      <a href="#" (click)="passwordChange()" #openModal data-target="#passwordModal" data-toggle="modal" onClick="return false;" >
                        <app-feather-icons [icon]="'edit-2'" [class]="'user-menu-icons'"></app-feather-icons>Change Password 
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
  </div>
</nav>