<div class=""> 
    <!--Mega Menu Kannan-->
    <nav class="navbar navbar-expand-lg">
      <a href="#" class="navbar-brand font-weight-bold d-block d-lg-none">MegaMenu</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarSupportedContent" class="collapse navbar">
        <ul class="navbar-nav mx-auto">
          <!-- Megamenu-->
          <li class="nav-item dropdown megamenu"><a id="megamneu" href="#" id="dropdownMenuButton1" data-bs-toggle="dropdown" 
            aria-expanded="false" class="nav-link dropdown-toggle font-weight-bold text-uppercase dropdown-toggle">CRM</a>
            <div aria-labelledby="dropdownMenuButton1" class="dropdown-menu border-0 p-0 m-0">
              <div class="container">
                <div class="row bg-white rounded-0 m-0 shadow-sm">
                  <div class="col-lg-7 col-xl-8">
                    <div class="p-4">
                      <div class="row">
                        <div class="col-lg-6 mb-4">
                          <h6 class="font-weight-bold text-uppercase">Master</h6>
                          <ul class="list-unstyled">
                            <li class="nav-item"><a [routerLink]="'/admin/employees/allEmployees'" class="nav-link text-small pb-0">Employee</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0 ">Sales Call entry</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Customer Master</a></li>
                          </ul>
                        </div>                        
                      </div>
                    </div>
                  </div>
                              
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown megamenu"><a id="megamneu" href="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" 
            aria-expanded="false" class="nav-link dropdown-toggle font-weight-bold text-uppercase dropdown-toggle">Marketing</a>
            <div aria-labelledby="dropdownMenuButton2" class="dropdown-menu border-0 p-0 m-0">
              <div class="container">
                <div class="row bg-white rounded-0 m-0 shadow-sm">
                  <div class="col-lg-7 col-xl-8">
                    <div class="p-4">
                      <div class="row">
                        <div class="col-lg-6 mb-4">
                          <h6 class="font-weight-bold text-uppercase">Heading</h6>
                          <ul class="list-unstyled">
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Sales Quote</a></li>
                          </ul>
                        </div>                        
                      </div>
                    </div>
                  </div>
                              
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown megamenu"><a id="megamneu" href="#" id="dropdownMenuButton3" data-bs-toggle="dropdown" 
            aria-expanded="false" class="nav-link dropdown-toggle font-weight-bold text-uppercase dropdown-toggle">Operations</a>
            <div aria-labelledby="dropdownMenuButton3" class="dropdown-menu border-0 p-0 m-0">
              <div class="container">
                <div class="row bg-white rounded-0 m-0 shadow-sm">
                  <div class="col-lg-7 col-xl-8">
                    <div class="p-4">
                      <div class="row">
                        <div class="col-lg-6 mb-4">
                          <h6 class="font-weight-bold text-uppercase">Docs</h6>
                          <ul class="list-unstyled">
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Docs Menu 1</a></li>
                          </ul>
                          <h6 class="font-weight-bold text-uppercase">BOM</h6>
                          <ul class="list-unstyled">
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Docs Menu 1</a></li>
                          </ul>
                          <h6 class="font-weight-bold text-uppercase">BOO</h6>
                          <ul class="list-unstyled">
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Docs Menu 1</a></li>
                          </ul>
                        </div>                        
                      </div>
                    </div>
                  </div>
                              
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown megamenu"><a id="megamneu" href="#" id="dropdownMenuButton4" data-bs-toggle="dropdown" 
            aria-expanded="false" class="nav-link dropdown-toggle font-weight-bold text-uppercase dropdown-toggle">Purchase</a>
            <div aria-labelledby="dropdownMenuButton4" class="dropdown-menu border-0 p-0 m-0">
              <div class="container">
                <div class="row bg-white rounded-0 m-0 shadow-sm">
                  <div class="col-lg-7 col-xl-8">
                    <div class="p-4">
                      <div class="row">
                        <div class="col-lg-6 mb-4">
                          <h6 class="font-weight-bold text-uppercase">Heading</h6>
                          <ul class="list-unstyled">
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Purchase Request</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Purchase Quote</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">LPO</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">GRN</a></li>
                          </ul>
                        </div>                        
                      </div>
                    </div>
                  </div>
                              
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown megamenu"><a id="megamneu" href="#" id="dropdownMenuButton5" data-bs-toggle="dropdown" 
            aria-expanded="false" class="nav-link dropdown-toggle font-weight-bold text-uppercase dropdown-toggle">Inventory</a>
            <div aria-labelledby="dropdownMenuButton5" class="dropdown-menu border-0 p-0 m-0">
              <div class="container">
                <div class="row bg-white rounded-0 m-0 shadow-sm">
                  <div class="col-lg-7 col-xl-8">
                    <div class="p-4">
                      <div class="row">
                        <div class="col-lg-6 mb-4">
                          <h6 class="font-weight-bold text-uppercase">Heading</h6>
                          <ul class="list-unstyled">
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">GRN</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Manual Stock Count</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Warehouse Positioning</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Gatepass</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">DO</a></li>
                          </ul>
                        </div>                        
                      </div>
                    </div>
                  </div>
                              
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown megamenu"><a id="megamneu" href="#" id="dropdownMenuButton6" data-bs-toggle="dropdown" 
            aria-expanded="false" class="nav-link dropdown-toggle font-weight-bold text-uppercase dropdown-toggle">Finance</a>
            <div aria-labelledby="dropdownMenuButton6" class="dropdown-menu border-0 p-0 m-0">
              <div class="container">
                <div class="row bg-white rounded-0 m-0 shadow-sm">
                  <div class="col-lg-7 col-xl-8">
                    <div class="p-4">
                      <div class="row">
                        <div class="col-lg-6 mb-4">
                          <h6 class="font-weight-bold text-uppercase">Billing</h6>
                          <ul class="list-unstyled">
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Invoice</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Purchase Invoice</a></li>
                          </ul>
                          <h6 class="font-weight-bold text-uppercase">Transaction</h6>
                          <ul class="list-unstyled">
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Bank Payment</a></li>
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Bank Receipt</a></li>
                          </ul>
                        </div>                        
                      </div>
                    </div>
                  </div>
                              
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown megamenu"><a id="megamneu" href="#" id="dropdownMenuButton7" data-bs-toggle="dropdown" 
            aria-expanded="false" class="nav-link dropdown-toggle font-weight-bold text-uppercase dropdown-toggle">MIS</a>
            <div aria-labelledby="dropdownMenuButton7" class="dropdown-menu border-0 p-0 m-0">
              <div class="container">
                <div class="row bg-white rounded-0 m-0 shadow-sm">
                  <div class="col-lg-7 col-xl-8">
                    <div class="p-4">
                      <div class="row">
                        <div class="col-lg-6 mb-4">
                          <h6 class="font-weight-bold text-uppercase">Reports</h6>
                          <ul class="list-unstyled">
                            <li class="nav-item"><a href="" class="nav-link text-small pb-0">Report</a></li>
                          </ul>
                        </div>                        
                      </div>
                    </div>
                  </div>
                              
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    
    
    <!-- End -->
    <!--Menu Menu-->
  </div>