<section class="content">
    <div class="content-block">
      <div class="block-header">
        <!-- breadcrumb -->
         <!-- <app-breadcrumb [title]="'Proforma Invoice'" [items]="['Finance']" [active_item]="'Proforma Invoice List'">
        </app-breadcrumb>   -->
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top:1.75em;">
          <div class="card">
            <div class="body">
  
  
              <!-- <div class="row">   
                <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 " style="margin-left: 75px;">
                    <mat-form-field class="example-full-width form-control-sm" appearance="outline">
                      <mat-label>CN No</mat-label>
                          <mat-select formControlName="cnNo" required>
                            <mat-option [value]="'1'">
                              Paragon
                            </mat-option>
                          </mat-select>
                         
                    </mat-form-field>
                  </div>  
  
                <div class="col-xl-1 col-lg-3 col-md-12 col-sm-12 mb-3" style="padding-top: 6.6px;">
                  <button (click) = "onSubmit()" class="btn-space" mat-raised-button
                    style="margin-left: 38%; color: aliceblue; background-color: green;">Reverse</button>
                  
                </div>
              </div> -->
  
  
              <div class="table-responsive">        
                <!-- <div class="materialTableHeader">
                  <div class="row">
                    <div class="col-8">
                      <ul class="header-buttons-left ms-0">
                        <li class="dropdown">
                          <h2>Proforma Invoice</h2>
                        </li>
                        <li class="dropdown m-l-20">
                          <label for="search-input"><i class="material-icons search-icon">search</i></label>
                          <input placeholder="Search" type="text" #filter class="browser-default search-field"
                            aria-label="Search box">
                        </li>
                        <li>
                          <div class="m-l-10" matTooltip="ADD">
                            <button mat-mini-fab color="primary"  [routerLink]="'/finance/billing/purchaseInvoice/addPurchaseInvoice/0'" >
                              <mat-icon class="col-white">add</mat-icon>
                            </button>
                          </div>
                        </li>
                        <li>
                          <div class="m-l-10" matTooltip="REFRESH">
                            <button mat-mini-fab color="primary" (click)="refresh()">
                              <mat-icon class="col-white">refresh</mat-icon>
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-4">
                      <ul class="header-buttons">
                        <li>
                          <div class="export-button m-l-10" matTooltip="XLSX">
                            <img src="assets/images/icons/xlsx.png" alt=""
                              (click)="exporter.exportTable('xlsx', {fileName:'GRN-list', sheet: 'sheet1'})" />
                          </div>
                        </li>
                        <li>
                          <div class="export-button m-l-10" matTooltip="CSV">
                            <img src="assets/images/icons/csv.png" alt="" (click)="exporter.exportTable('csv',{fileName:'GRN-list'})" />
                          </div>
                        </li>
                        <li>
                          <div class="export-button m-l-10" matTooltip="JSON">
                            <img src="assets/images/icons/json.png" alt="" (click)="exporter.exportTable('json',{fileName:'GRN-list'})" />
                          </div>
                        </li>
                        <li>
                          <div class="export-button m-l-10" matTooltip="TXT">
                            <img src="assets/images/icons/txt.png" alt="" (click)="exporter.exportTable('txt',{fileName:'GRN-list'})" />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>  -->
                <mat-table #table matTableExporter [dataSource]="dataSource" matSort class="mat-cell"
                #exporter="matTableExporter">
     
                  <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->
              
                  <ng-container matColumnDef="purchaseInvoiceNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Proforma Invoice</mat-header-cell>
                    <mat-cell *matCellDef="let row" >{{row.purchaseInvoiceNo}}</mat-cell>
                  </ng-container>
              
                  <ng-container matColumnDef="invoiceDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Date</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.invoiceDate}}</mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="companyName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.companyName}}</mat-cell>
                  </ng-container>
  
                  <!-- <ng-container matColumnDef="manualInvoiceNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Manual Invoice No</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.manualInvoiceNo}}</mat-cell>
                  </ng-container> -->
  
                  <!-- <ng-container matColumnDef="creditCompany">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Company</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.creditVendorCustomerName}}</mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="creditTotalTcAmt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.creditCurrency}}</mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="creditTotalBcAmt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Amount USD</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.creditInvoiceNumber}}</mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="creditNarration">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.creditNarration}}</mat-cell>
                  </ng-container> -->
  
                  
                  <!-- actions -->
                  <ng-container matColumnDef="actions">
                    <mat-header-cell class="pr-0" *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let row; let i=index;" class="pr-0">
                      <!-- <button mat-icon-button  (click)="$event.stopPropagation()" (click)="viewCall(row)"
                      class="tbl-action-btn">
                      <i class="fa fa-eye" style="color:#33b91f;"aria-hidden="true"></i> 
                    </button> -->
                    <button mat-icon-button color="accent" (click)="$event.stopPropagation()" (click)="viewCall(row)"
                    class="tbl-action-btn">
                    <app-feather-icons [icon]="'check'" [class]="'tbl-fav-edit'"></app-feather-icons>
                  </button>  
                      <!-- <button mat-icon-button color="accent" (click)="$event.stopPropagation()"
                        (click)="deleteItem(row)" class="tbl-action-btn">
                        <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                      </button> -->
                    </mat-cell>
                  </ng-container>
                  <!-- <ng-container matColumnDef="actions">
                    <mat-header-cell class="pr-0" *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let row; let i=index;" class="pr-0">
                      <button mat-icon-button color="accent" (click)="$event.stopPropagation()" (click)="editCall(row)"
                        class="tbl-action-btn">
                        <app-feather-icons [icon]="'edit'" [class]="'tbl-fav-edit'"></app-feather-icons>
                      </button>
                      <button mat-icon-button color="accent" (click)="$event.stopPropagation()"
                        (click)="deleteItem(row)" class="tbl-action-btn">
                        <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                      </button>
                    </mat-cell>
                  </ng-container> -->
                
                  
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <!-- <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row> -->
                <mat-row *matRowDef="let row; columns: displayedColumns;"
                  [style.cursor]="'pointer'" matRipple>
                </mat-row>
              </mat-table>
              <!-- Loading spinner -->
              <div *ngIf="exampleDatabase.isTblLoading" class="tbl-spinner">
                <mat-progress-spinner color="primary" [diameter]="40" mode="indeterminate">
                </mat-progress-spinner>
              </div>
              <!-- context menu start -->
              <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
                [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
              </div>
              <mat-menu #contextMenu="matMenu">
                <ng-template matMenuContent let-item="item">
                  <button mat-menu-item (click)="addNew()">
                    <mat-icon>add_box</mat-icon>
                    <span>Add Record</span>
                  </button>
                  <button mat-menu-item (click)="editCall(item)">
                    <mat-icon>create</mat-icon>
                    <span>Edit Record</span>
                  </button>
                  <button mat-menu-item (click)="deleteItem(item.id,item)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete Record</span>
                  </button>
                  <button mat-menu-item (click)="refresh()">
                    <mat-icon>refresh</mat-icon>
                    <span>Refresh Record</span>
                  </button>
                  <button mat-menu-item disabled>
                    <mat-icon>no_encryption</mat-icon>
                    <span>Disable</span>
                  </button>
                  <button mat-menu-item [matMenuTriggerFor]="nestedmenu">
                    <mat-icon>list_alt</mat-icon>
                    <span> Nested Menu</span>
                  </button>
                </ng-template>
              </mat-menu>
              <mat-menu #nestedmenu="matMenu">
                <button mat-menu-item>
                  <mat-icon>mail_outline</mat-icon>
                  <span>Item 1</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>call</mat-icon>
                  <span>Item 2</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>chat</mat-icon>
                  <span>Item 3</span>
                </button>
              </mat-menu>
              <!-- context menu end -->
              <div *ngIf="!exampleDatabase.isTblLoading" class="no-results"
                [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'">
                No results
              </div>
              <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </section>