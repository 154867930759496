import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpServiceService } from 'src/app/auth/http-service.service';
import { serverLocations } from 'src/app/auth/serverLocations';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { GRNResultBean } from './grn-result-bean';
import { Grn } from './grn.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class GrnService extends UnsubscribeOnDestroyAdapter{
  isTblLoading = true;
  dataChange: BehaviorSubject<Grn[]> = new BehaviorSubject<Grn[]>(
    []
  );
  // Temporarily stores data from dialogs
  dialogData: any;

  constructor(private httpClient:HttpClient, private serverUrl: serverLocations, private httpService: HttpServiceService) { 
    super();
  }

  public getPOListVal = `${this.serverUrl.apiServerAddress}app/grn/getPOList`;
  public getPODListVal = `${this.serverUrl.apiServerAddress}app/grn/getPODtlList`; 
  public getRequisitionVal = `${this.serverUrl.apiServerAddress}app/grn/getRequisition`;
  public getLocationVal =  `${this.serverUrl.apiServerAddress}app/grn/getLocationList`;
  public uomListVal = `${this.serverUrl.apiServerAddress}app/workOrder/getUomList`;
  public vendorListVal = `${this.serverUrl.apiServerAddress}app/grn/getVendorList`;
  public vendorAddressVal =  `${this.serverUrl.apiServerAddress}app/grn/getVendorAddress`;
  public getPOListVal1 = `${this.serverUrl.apiServerAddress}app/grn/getPOList1`;
  public saveGRN = `${this.serverUrl.apiServerAddress}app/grn/saveGRN`;
  public listGRN = `${this.serverUrl.apiServerAddress}app/grn/listGRN`;
  public listQualityCheck = `${this.serverUrl.apiServerAddress}app/grn/listQualityCheck`;
  public viewGRN = `${this.serverUrl.apiServerAddress}app/grn/getGrnEditData`;
  public saveQualityData = `${this.serverUrl.apiServerAddress}app/grn/saveQuality`;
  public getPendingLPO = `${this.serverUrl.apiServerAddress}app/grn/getPendingLPO`;

  //batch
  public itemAttributes =  `${this.serverUrl.apiServerAddress}app/grn/getItemAttributes`;

  public deleteGrn = `${this.serverUrl.apiServerAddress}app/grn/delete`;

  get data(): Grn[] {
    return this.dataChange.value;
  }

   /** CRUD METHODS */ 
   addGRN(grn: Grn,router,notificationService,spinner): void {
    this.dialogData = grn;
    this.httpService.post<Grn>(this.saveGRN, grn).subscribe(data => {
      console.log(data);
      if(data.Success==true){
        spinner.hide();
        notificationService.showNotification(
          "snackbar-success",
          "Record Added successfully...",
          "bottom",
          "center"
        );
        router.navigate(['/purchase/grn/listGrn']);
      }
      else if(data.Success==false){
        spinner.hide();
        notificationService.showNotification(
          "snackbar-danger",
          "Not Updated ...!!!",
          "bottom",
          "center"
        );
      }
      },
      (err: HttpErrorResponse) => {
        
    });
  }
  
  saveQuality(grn: Grn,router,notificationService){
    this.dialogData = grn;
    this.httpService.post<Grn>(this.saveQualityData, grn).subscribe(data => {
      console.log(data);
      // if(data){
        notificationService.showNotification(
          "snackbar-success",
          "Record Added successfully...",
          "bottom",
          "center"
        );
      //}
      // else{
      //   notificationService.showNotification(
      //     "snackbar-danger",
      //     "Not Updated ...!!!",
      //     "bottom",
      //     "center"
      //   );
      // }
      },
      (err: HttpErrorResponse) => {
        
    });
  }

  getAllList(): void {
    this.subs.sink = this.httpService.get<GRNResultBean>(this.listGRN).subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.lGRNBean);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
}

getNewList(): void {
  this.subs.sink = this.httpService.get<GRNResultBean>(this.listQualityCheck).subscribe(
    (data) => {
      this.isTblLoading = false;
      this.dataChange.next(data.lGRNBean);
    },
    (error: HttpErrorResponse) => {
      this.isTblLoading = false;
      console.log(error.name + " " + error.message);
    }
  );
}
 
// DeleteGrn(grnId:any): void {
//     this.httpService.get(this.deleteGrn+"?grnCode="+grnId).subscribe(data => {
//     console.log(grnId);
//       // this.httpClient.delete(this.API_URL + id).subscribe(data => {
//       // console.log(id);
//       },
//       (err: HttpErrorResponse) => {
//          // error code here
//       }
//     );
//   }


DeleteGrn(grnId: any): Observable<any>  {

  return  this.httpClient.get<any>(this.deleteGrn+"?grnCode="+grnId);
  
};
}
