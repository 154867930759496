<ng-container *ngFor="let menuItem of navItems">

	<ng-container *ngIf="menuItem.subMenuList == null || menuItem.subMenuList.length == 0">
		<a class="nav-link" [routerLink]="menuItem.formurl">
			<span class="icon fa" [ngClass]="menuItem.iconName"></span>
      <span class="text-holder">{{menuItem.formname}}</span>
    </a>
  </ng-container>
  <ng-container *ngIf="menuItem.subMenuList.length > 0">
<button #button mat-button [matMenuTriggerFor]="levelOne" #levelOneTrigger="matMenuTrigger" 
(mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)" style="z-index:1050; color: azure;">
      <span class="icon fa" [ngClass]="menuItem.iconName"></span>
      <span>{{menuItem.formname}} </span>
      <span class="material-icons">arrow_drop_down</span>
    </button>    

    <mat-menu #levelOne="matMenu" direction="down" yPosition="below" [overlapTrigger]="false" [hasBackdrop]="false">
      <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(levelOneTrigger, button)">
      <ng-container *ngFor="let childL1 of menuItem.subMenuList">
        <li class="p-0" *ngIf="childL1.subMenuList == null || childL1.subMenuList.length == 0" mat-menu-item>
          <a style="color: rgb(0, 0, 0) !important;" class="nav-link" [routerLink]="childL1.formurl">{{childL1.formname}}
            <i *ngIf="childL1.iconName" [ngClass]="childL1.iconName"></i>
          </a>
        </li>
        <ng-container *ngIf="childL1.subMenuList != null && childL1.subMenuList.length > 0">
          <li mat-menu-item #levelTwoTrigger="matMenuTrigger" [matMenuTriggerFor]="levelTwo">
            <span class="icon fa" [ngClass]="childL1.iconName"></span>
            <span style="color: rgb(0, 0, 0) !important;">{{childL1.formname}}</span>
          </li>

          <mat-menu #levelTwo="matMenu" [hasBackdrop]="false">
            <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(levelOneTrigger,levelTwoTrigger, button)">
            <ng-container *ngFor="let childL2 of childL1.subMenuList">
              <li class="p-0" mat-menu-item>
                <a style="color: rgb(0, 0, 0) !important;"class="nav-link" [routerLink]="childL2.formurl">{{childL2.formname}}
                  <i *ngIf="childL2.iconName" [ngClass]="childL2.iconName"></i>
                </a>
              </li>
            </ng-container>
            </span>
          </mat-menu>
        </ng-container>
      </ng-container>
      </span>
    </mat-menu>
  </ng-container>

</ng-container>