<section class="content">
    <div class="content-block">
      <div class="block-header">
        <!-- breadcrumb -->
         <!-- <app-breadcrumb [title]="'Proforma Invoice'" [items]="['Finance']" [active_item]="'Proforma Invoice List'">
        </app-breadcrumb>   -->
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top:1.75em;">
          <div class="card">
            <div class="body">
              <div class="table-responsive">        
                <mat-table #table matTableExporter [dataSource]="dataSource" matSort class="mat-cell"
                #exporter="matTableExporter">
     
                  <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->
              
                  <ng-container matColumnDef="customer">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Customer</mat-header-cell>
                    <mat-cell *matCellDef="let row" >{{row.customer}}</mat-cell>
                  </ng-container>
              
                  <ng-container matColumnDef="customerType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Customer Type</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.customerType}}</mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="visitDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Visited Date</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.visitDate}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.status}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="nextCallDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Next Call Date</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.nextCallDate}}</mat-cell>
                  </ng-container>
  
                       
                  <!-- actions -->
                  <!-- <ng-container matColumnDef="actions">
                    <mat-header-cell class="pr-0" *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let row; let i=index;" class="pr-0">
                     <button mat-icon-button  (click)="$event.stopPropagation()" (click)="viewCall(row)"
                      class="tbl-action-btn">
                      <i class="fa fa-eye" style="color:#33b91f;"aria-hidden="true"></i> 
                    </button> 
                    <button mat-icon-button color="accent" (click)="$event.stopPropagation()" (click)="viewCall(row)"
                    class="tbl-action-btn">
                    <app-feather-icons [icon]="'check'" [class]="'tbl-fav-edit'"></app-feather-icons>
                  </button>  
                <button mat-icon-button color="accent" (click)="$event.stopPropagation()"
                        (click)="deleteItem(row)" class="tbl-action-btn">
                        <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'"></app-feather-icons>
                      </button>
                    </mat-cell>
                  </ng-container> -->
              
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <!-- <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row> -->
                <mat-row *matRowDef="let row; columns: displayedColumns;"
                  [style.cursor]="'pointer'" matRipple>
                </mat-row>
              </mat-table>
              <!-- Loading spinner -->
              <div *ngIf="exampleDatabase.isTblLoading" class="tbl-spinner">
                <mat-progress-spinner color="primary" [diameter]="40" mode="indeterminate">
                </mat-progress-spinner>
              </div>
              <!-- context menu start -->
              <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
                [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
              </div>
              <mat-menu #contextMenu="matMenu">
                <ng-template matMenuContent let-item="item">
                  <button mat-menu-item (click)="addNew()">
                    <mat-icon>add_box</mat-icon>
                    <span>Add Record</span>
                  </button>
                  <button mat-menu-item (click)="editCall(item)">
                    <mat-icon>create</mat-icon>
                    <span>Edit Record</span>
                  </button>
                  <button mat-menu-item (click)="deleteItem(item.id,item)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete Record</span>
                  </button>
                  <button mat-menu-item (click)="refresh()">
                    <mat-icon>refresh</mat-icon>
                    <span>Refresh Record</span>
                  </button>
                  <button mat-menu-item disabled>
                    <mat-icon>no_encryption</mat-icon>
                    <span>Disable</span>
                  </button>
                  <button mat-menu-item [matMenuTriggerFor]="nestedmenu">
                    <mat-icon>list_alt</mat-icon>
                    <span> Nested Menu</span>
                  </button>
                </ng-template>
              </mat-menu>
              <mat-menu #nestedmenu="matMenu">
                <button mat-menu-item>
                  <mat-icon>mail_outline</mat-icon>
                  <span>Item 1</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>call</mat-icon>
                  <span>Item 2</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>chat</mat-icon>
                  <span>Item 3</span>
                </button>
              </mat-menu>
              <!-- context menu end -->
              <div *ngIf="!exampleDatabase.isTblLoading" class="no-results"
                [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'">
                No results
              </div>
              <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </section>