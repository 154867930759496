import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpServiceService } from 'src/app/auth/http-service.service';
import { serverLocations } from 'src/app/auth/serverLocations';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { PurchaseInvoice } from './purchase-invoice.model';
import { PurchaseInvoiceResultBean } from './purchase-invoice-result-bean';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoiceService extends UnsubscribeOnDestroyAdapter {

  isTblLoading = true;
  dataChange: BehaviorSubject<PurchaseInvoice[]> = new BehaviorSubject<PurchaseInvoice[]>(
    []
  );
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient,
    private serverUrl: serverLocations,
    private httpService: HttpServiceService) {
    super();
  }

  private getAllMasters = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/getList`;
  private getAllProformaListURL = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/getAllProformaList`;
  private savePurchaseInvoice = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/save`;
  public editPurchaseInvoice = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/edit`;
  public viewPurchaseInvoice = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/view`;
  public printPurchaseInvoice = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/print`;
  public itemNameList = `${this.serverUrl.apiServerAddress}app/saleOrder/itemNameList`;
  public allItemListURL = `${this.serverUrl.apiServerAddress}app/saleOrder/allItemList`;
  public customerList = `${this.serverUrl.apiServerAddress}app/saleOrder/getCustomerList`;
  public currencyListUrl = `${this.serverUrl.apiServerAddress}app/countryMaster/getCurrencyList`;
  public getManualInvoiceNo = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/getinvoiceNo`;
  public salesOrderUrl = `${this.serverUrl.apiServerAddress}app/saleOrder/getSalesOrderListService`;
  public invoiceListCountUrl = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/invoiceListCount`;
  public fetchSalesQuoteNo = `${this.serverUrl.apiServerAddress}app/saleOrder/getfetchCustomer`;
  public getSalesCallAlertList = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/getSalesCallAlertList`;
  public update = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/update`;
  public approved = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/approved`;
  public getProformaApprovedListUrl = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/getProformaApprovedList`;

  public fetchExchangeRateDetails = `${this.serverUrl.apiServerAddress}app/exchangeRate/getExchangeRate`;
  public getPurchaseInvoice = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/getList`;
  public mailSent = `${this.serverUrl.apiServerAddress}app/purchaseInvoice/mail`;

  get data(): PurchaseInvoice[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */

  // This is for save
  addPurchaseInvoice(purchaseInvoice: PurchaseInvoice, router, notificationService, spinner): void {
    this.dialogData = purchaseInvoice;
    this.httpService.post<PurchaseInvoice>(this.savePurchaseInvoice, purchaseInvoice).subscribe(data => {
      console.log(data);
      if (data.Success === true) {
        spinner.hide();
        notificationService.showNotification(
          "snackbar-success",
          "Record Added successfully...!!!",
          "bottom",
          "center"
        );
        router.navigate(['/finance/billing/purchaseInvoice/listPurchaseInvoice']);
      }
      else if (data.Success === false) {
        spinner.hide();
        notificationService.showNotification(
          "snackbar-danger",
          "Not Updated ...!!!",
          "bottom",
          "center"
        );
      }
    },
      (err: HttpErrorResponse) => {
      });
  }

  getAllList(): void {
    this.subs.sink = this.httpService.get<PurchaseInvoiceResultBean>(this.getAllMasters).subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.purchaseInvoiceDetails);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }

  getProformaList(): void {
    this.subs.sink = this.httpService.get<PurchaseInvoiceResultBean>(this.getAllProformaListURL).subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.proformaInvoiceDetails);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }

  getSalesAlertList(): void {
    this.subs.sink = this.httpService.get<PurchaseInvoiceResultBean>(this.getSalesCallAlertList).subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.proformaInvoiceDetails);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }


  UpdateSalesQuote(purchaseInvoice: PurchaseInvoice, router, notificationService, spinner): void {
    this.dialogData = purchaseInvoice;
    this.httpService.post<PurchaseInvoice>(this.update, purchaseInvoice).subscribe(data => {
      console.log(data);
      if (data.Success === true) {
        spinner.hide();
        notificationService.showNotification(
          "snackbar-success",
          "Updated Record Successfully...!!!",
          "bottom",
          "center"
        );
        router.navigate(['/finance/billing/purchaseInvoice/listPurchaseInvoice']);
      } else if (data.Success === false) {
        spinner.hide();
        notificationService.showNotification(
          "snackbar-danger",
          "Not Updated ...!!!",
          "bottom",
          "center"
        );
      } else {
        spinner.hide();
        notificationService.showNotification(
          "snackbar-danger",
          "Please fill all the required details!",
          "bottom",
          "center"
        );
      }
    },
      (err: HttpErrorResponse) => {

      });
  }


  approvedSalesQuote(purchaseInvoice: PurchaseInvoice, router, notificationService): void {
    this.dialogData = purchaseInvoice;
    this.httpService.post<PurchaseInvoice>(this.approved, purchaseInvoice).subscribe(data => {
      console.log(data);
      if (data.Success === true) {
        notificationService.showNotification(
          "snackbar-success",
          "Updated Record Successfully...!!!",
          "bottom",
          "center"
        );
        router.navigate(['/finance/billing/purchaseInvoice/listPurchaseInvoice']);
      } else if (data.Success === false) {
        notificationService.showNotification(
          "snackbar-danger",
          "Not Updated ...!!!",
          "bottom",
          "center"
        );
      } else {
        notificationService.showNotification(
          "snackbar-danger",
          "Please fill all the required details!",
          "bottom",
          "center"
        );
      }
    },
      (err: HttpErrorResponse) => {

      });
  }


  MailSent(countValue: any): void {
    this.httpService.get(this.mailSent + "?mailOrder=" + countValue).subscribe(data => {
      console.log(data);
    },
      (err: HttpErrorResponse) => {
        // error code here
      }
    );
  }
}
