<!-- src/app/menu/menu.component.html -->

<button *ngIf="isRootNode" mat-button [matMenuTriggerFor]="menu" (mouseover)="controlsmenuTrigger.openMenu()">{{trigger}}
  <a class="nav-link" #controlsmenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></a>
</button>
  <button
    *ngIf="!isRootNode"
    mat-menu-item
    [matMenuTriggerFor]="menu"
    (menuOpened)="getData(trigger)"
    (mouseover)="controlsmenuTrigger.openMenu()"
  >
    {{ trigger }}
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngIf="isLoading"
      style="display: flex; justify-content: center; align-items: center"
    >
    </button>
    <ng-container *ngFor="let node of data; let i = index">
      <button mat-menu-item style="padding: 0">
        <app-matmenu
          [trigger]="node"
          *ngIf="isExpandable(node); else menuItem"
        ></app-matmenu>
      </button>
      <ng-template #menuItem>
        <button mat-menu-item>{{ node }}</button>
      </ng-template>
    </ng-container>
  </mat-menu>
  