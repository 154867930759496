import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, fromEvent, map, merge, Observable } from 'rxjs';
// import { ExampleDataSource } from 'src/app/admin/employees/allEmployees/allemployees.component';
import { EmployeesService } from 'src/app/admin/employees/allEmployees/employees.service';
import { HttpServiceService } from 'src/app/auth/http-service.service';
import { serverLocations } from 'src/app/auth/serverLocations';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';

import { Router, ActivatedRoute } from '@angular/router';
import { GrnService } from 'src/app/purchase/grn/grn.service';
import { Grn } from 'src/app/purchase/grn/grn.model';
import { PurchaseInvoiceService } from 'src/app/finance/billing/purchase-invoice/purchase-invoice.service';
import { PurchaseInvoice } from 'src/app/finance/billing/purchase-invoice/purchase-invoice.model'; 
import { EncrDecrService } from 'src/app/core/service/encrDecr.Service';

@Component({
  selector: 'app-header-mail-pop-up',
  templateUrl: './header-mail-pop-up.component.html',
  styleUrls: ['./header-mail-pop-up.component.sass']
})
export class HeaderMailPopUpComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  displayedColumns = ['purchaseInvoiceNo', 'invoiceDate','companyName',
 // 'manualInvoiceNo',
  // 'creditCompany','creditTotalTcAmt','creditTotalBcAmt','creditNarration',
  'actions'];
  dataSource: ExampleDataSource | null;
  exampleDatabase: PurchaseInvoiceService | null;
  selection = new SelectionModel<PurchaseInvoice>(true, []);
  index: number;
  id: number;
  grn: PurchaseInvoice  | null;

  constructor(
    public httpClient: HttpClient,
    public dialog: MatDialog,
    public grnService: GrnService,
    private serverUrl:serverLocations,
    private httpService:HttpServiceService,
    public router: Router,
    public route: ActivatedRoute,
    public purchaseInvoiceService:PurchaseInvoiceService,
    private EncrDecr:EncrDecrService
  ) {
    super();
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("filter", { static: true }) filter: ElementRef;
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: "0px", y: "0px" };

  ngOnInit(): void {
    this.loadData();

    // if (!localStorage.getItem('foo')) { 
    //   localStorage.setItem('foo', 'no reload') 
    //   location.reload() 
    // } else {
    //   localStorage.removeItem('foo') 
    // }
    
  }
  refresh(){
    this.loadData();
  }
  public loadData() {
    this.exampleDatabase = new PurchaseInvoiceService(this.httpClient, this.serverUrl, this.httpService);
    this.dataSource = new ExampleDataSource(
      this.exampleDatabase,
      this.paginator,
      this.sort
    );
    this.subs.sink = fromEvent(this.filter?.nativeElement, "keyup").subscribe(
      () => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter?.nativeElement.value;
      }
    );
  }
  editCall(row) { 
     this.router.navigate(['/finance/billing/purchaseInvoice/addPurchaseInvoice/'+row.purchaseInvoiceNo]);
  }
  viewCall(row) { 
    var encrypted = this.EncrDecr.set(this.serverUrl.secretKey, row.purchaseInvoiceNo);
    this.router.navigate(['/finance/billing/purchaseInvoice/viewPurchaseInvoice/', encrypted]);
    this.dialog.closeAll();
 }
  onSubmit(){
    
  }

deleteItem(i, row){

}
// context menu
onContextMenu(event: MouseEvent, item: PurchaseInvoice) {
  event.preventDefault();
  this.contextMenuPosition.x = event.clientX + "px";
  this.contextMenuPosition.y = event.clientY + "px";
  this.contextMenu.menuData = { item: item };
  this.contextMenu.menu.focusFirstItem("mouse");
  this.contextMenu.openMenu();
}
}
export class ExampleDataSource extends DataSource<PurchaseInvoice> {
  filterChange = new BehaviorSubject("");
  get filter(): string {
    return this.filterChange.value;
  }
  set filter(filter: string) {
    this.filterChange.next(filter);
  }
  filteredData: PurchaseInvoice[] = [];
  renderedData: PurchaseInvoice[] = [];
  constructor(
    public exampleDatabase: PurchaseInvoiceService,
    public paginator: MatPaginator,
    public _sort: MatSort
  ) {
    super();
    // Reset to the first page when the user changes the filter.
    this.filterChange.subscribe(() => (this.paginator.pageIndex = 0));
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<PurchaseInvoice[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.exampleDatabase.dataChange,
      this._sort.sortChange,
      this.filterChange,
      this.paginator.page,
    ];
    this.exampleDatabase.getProformaList();
    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this.exampleDatabase.data
          .slice()
          .filter((currencyMaster: PurchaseInvoice) => {
            const searchStr = (
              currencyMaster.invoiceDate +
              currencyMaster.companyName +
              currencyMaster.manualInvoiceNo +
              currencyMaster.quotation +
              currencyMaster.currency +
              currencyMaster.dueDate +
              currencyMaster.customer          
            ).toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
          });
        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());
        // Grab the page's slice of the filtered sorted data.
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        this.renderedData = sortedData.splice(
          startIndex,
          this.paginator.pageSize
        );
        return this.renderedData;
      })
    );
  }
  disconnect() {}
  /** Returns a sorted copy of the database data. */
  sortData(data: PurchaseInvoice[]): PurchaseInvoice[] {
    if (!this._sort.active || this._sort.direction === "") {
      return data;
    }
    return data.sort((a, b) => {
      let propertyA: number | string = "";
      let propertyB: number | string = "";
      switch (this._sort.active) {
        case "creditNoteDate":
          [propertyA, propertyB] = [a.invoiceDate, b.invoiceDate];
          break;
        case "creditInvoiceDate":
          [propertyA, propertyB] = [a.companyName, b.companyName];
          break;
        
        case "creditNarration":
          [propertyA, propertyB] = [a.manualInvoiceNo, b.manualInvoiceNo];
          break;
          
        case "creditVendorCustomerName":
          [propertyA, propertyB] = [a.quotation, b.quotation];
          break;

          case "creditCurrency":
            [propertyA, propertyB] = [a.currency, b.currency];
            break;    

        
      }
      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      return (
        (valueA < valueB ? -1 : 1) * (this._sort.direction === "asc" ? 1 : -1)
      );
    });
  }
}



