import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpServiceService } from 'src/app/auth/http-service.service';
import { serverLocations } from 'src/app/auth/serverLocations';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { PurchaseRequestResultBean } from './purchase-request-result-bean';
import { PurchaseRequest } from './purchase-request.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class PurchaseRequestService extends UnsubscribeOnDestroyAdapter{
  
  isTblLoading = true;
  dataChange: BehaviorSubject<PurchaseRequest[]> = new BehaviorSubject<PurchaseRequest[]>(
    []
  );
  // Temporarily stores data from dialogs
  dialogData: any;

  constructor(private httpClient:HttpClient, private serverUrl: serverLocations, private httpService: HttpServiceService) { 
    super();
  }
  private getAllPurchase = `${this.serverUrl.apiServerAddress}app/purchaseRequest/getList`;
  public getPendingPurchaseRequest = `${this.serverUrl.apiServerAddress}app/purchaseRequest/getPendingList`;
  private savePurchase = `${this.serverUrl.apiServerAddress}app/purchaseRequest/save`;
  public editPurchase = `${this.serverUrl.apiServerAddress}app/purchaseRequest/edit`;
  public updatePurchase = `${this.serverUrl.apiServerAddress}app/purchaseRequest/update`;
  public deletePurchase = `${this.serverUrl.apiServerAddress}app/purchaseRequest/delete`;
  public locationList = `${this.serverUrl.apiServerAddress}app/inventory/deliveryorder/getLocationList`;
  // public requisitionNo = `${this.serverUrl.apiServerAddress}app/purchaseRequest/getRequisitionNo`;
  // public requisitionNoList = `${this.serverUrl.apiServerAddress}app/purchaseRequest/getRequisitionNoList`;
  public itemNameList = `${this.serverUrl.apiServerAddress}app/saleOrder/itemNameList`;
  public countrylist = `${this.serverUrl.apiServerAddress}app/saleOrder/countryList`;
  public purchaseDetailsReqNo = `${this.serverUrl.apiServerAddress}app/purchaseRequest/getPurchaseDetailsReqNo`;
  public purchaseRequisitionNoList = `${this.serverUrl.apiServerAddress}app/purchaseRequest/getPurchaseReqNoList`;
  public viewPurchase = `${this.serverUrl.apiServerAddress}app/purchaseRequest/view`;
  public addCreditFiles = `${this.serverUrl.apiServerAddress}app/purchaseRequest/uploadFile`; 
  public getAllPagePermissionList = `${this.serverUrl.apiServerAddress}api/auth/app/userRights/getAllPagePermissionList`;
  public importExcelUrl = `${this.serverUrl.apiServerAddress}app/purchaseRequest/multipleUpload`;
  public saveMultiple = `${this.serverUrl.apiServerAddress}app/purchaseRequest/multipleSave`;

  get data(): PurchaseRequest[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */ 
  getAllList(object): void {
    this.subs.sink = this.httpService.post<PurchaseRequestResultBean>(this.getAllPurchase,object).subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.purchaseRequestDetails);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }

  getList(): void {
    this.subs.sink = this.httpService.get<PurchaseRequestResultBean>(this.getPendingPurchaseRequest).subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data.purchaseRequestDetails);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }
  addPurchase(purchaseRequest: PurchaseRequest,router,notificationService): void {
    this.dialogData = purchaseRequest;
    this.httpService.post<PurchaseRequest>(this.savePurchase, purchaseRequest).subscribe(data => {
      console.log(data);
      if(data.success==true){
        notificationService.showNotification(
          "snackbar-success",
          "Record Added successfully...",
          "bottom",
          "center"
        );
       router.navigate(['/purchase/purchaseRequest/listPurchase']);
      }
      else if(data.success==false){
        notificationService.showNotification(
          "snackbar-danger",
          "Not Updated ...!!!",
          "bottom",
          "center"
        );
      }
      },
      (err: HttpErrorResponse) => {
        
    });
  }
  
  UpdatePurchase(purchaseRequest: PurchaseRequest,router,notificationService): void {
    this.dialogData = purchaseRequest;
    this.httpService.post<PurchaseRequest>(this.updatePurchase, purchaseRequest).subscribe(data => {
    console.log(data);
    if(data.success==true){
      notificationService.showNotification(
        "snackbar-success",
        "Updated Record Successfully...!!!",
        "bottom",
        "center"
      );
      router.navigate(['/purchase/purchaseRequest/listPurchase']);
    }
    else if(data.success==false){
      notificationService.showNotification(
        "snackbar-danger",
        "Not Updated...!!!",
        "Not Updated ...!!!",
        "bottom",
        "center"
      );
    }
    },
    (err: HttpErrorResponse) => {
      
  });
  }

  addMultiple(obj: any): Observable<any> {
    return this.httpClient.post<any>(this.saveMultiple, obj);
  }

 
  // DeletePurchase(requisitionId:any): void {
  //   this.httpService.get(this.deletePurchase+"?purchaseRequest="+requisitionId).subscribe(data => {
  //   console.log(requisitionId);
  //     // this.httpClient.delete(this.API_URL + id).subscribe(data => {
  //     // console.log(id);
  //     },
  //     (err: HttpErrorResponse) => {
  //        // error code here
  //     }
  //   );
  // }

  
  DeletePurchase(requisitionId: any): Observable<any>  {

    return  this.httpClient.get<any>(this.deletePurchase+"?purchaseRequest="+requisitionId);
    
    
  }

  getAllPagePermission(obj: any): Observable<any> {
    return  this.httpClient.post<any>(this.getAllPagePermissionList, obj);
  }
  
}
